import { List } from "@mui/material";
import React, { FC } from "react";

import NavigationLink from "../../../../interfaces/NavigationLink";
import { classnames } from "../../../../utils";
import SidebarListItem from "../SidebarListItem/SidebarListItem";
import styles from "./SidebarList.module.scss";

interface SidebarListProps {
  list: NavigationLink[];
  isParentElement?: boolean;
}

const SidebarList: FC<SidebarListProps> = ({
  list,
  isParentElement = false
}) => {
  return (
    <List
      id={styles.sidebarList}
      className={classnames({ [styles.parentElement]: isParentElement })}
    >
      {list.map((navListItem, idx) => (
        <SidebarListItem
          {...navListItem}
          isParentElement={isParentElement}
          index={idx}
        />
      ))}
    </List>
  );
};

export default SidebarList;
