import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useRouteError } from 'react-router-dom';

import errorImage from '../../assets/images/AppError.jpg';
import Navbar from '../../layouts/Dashboard/components/Navbar/Navbar';
import styles from './ErrorBoundary.module.scss';

const ErrorBoundary = () => {
  const navigate = useNavigate();
  const appError = useRouteError() as Error;

  const handleNavigateToHome = () => {
    navigate('/', { replace: true });
  };

  // This should be logged
  // console.log({
  //   message: appError.message,
  //   name: appError.name,
  //   stack: appError.stack,
  //   cause: appError.cause,
  // });

  return (
    <Box id={styles.errorBoundary}>
      <Navbar onToggleSidebar={() => {}} hideSidebar />
      <Box className={styles.errorBoundaryBody}>
        <Box className={styles.errorImageContainer}>
          <img
            className={styles.errorImage}
            src={errorImage}
            alt='Application Error'
          />
        </Box>
        <Box className={styles.errorBoundaryInfo}>
          <Typography className={styles.errorBoundaryInfoTitle} variant='h3'>
            Oops! Hit a Glitch
          </Typography>
          <Typography
            className={styles.errorBoundaryInfoDescription}
            variant='body1'
            color='secondary'
          >
            {appError.message || 'Something went wrong'}
          </Typography>
          <Button size='large' onClick={handleNavigateToHome}>
            Back to Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default ErrorBoundary;
