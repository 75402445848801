import React from "react";
import { RouteObject } from "react-router-dom";

import Error404 from '../error/Error404/Error404';
import ErrorBoundary from '../error/ErrorBoundary/ErrorBoundary';
import PageGuard from '../guards/PageGuard';
import DashboardLayout from '../layouts/Dashboard';

const routesPages: RouteObject[] = [];

const Routes: RouteObject[] = [
  {
    path: "/",
    element: <DashboardLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <PageGuard />,
        children: routesPages,
      },
    ],
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

export default Routes;
