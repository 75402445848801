import POSFlowImage from '../../../../assets/images/POSFlowImage';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Link } from '@mui/material';
import styles from './POSIntegration.module.scss';

const POSIntegration = () => {
	return <Box className={styles.posIntegration}>
		<Typography variant="h3">POS Integration Docket</Typography>

		<Box id="Introduction" className={styles.section}>
			<Typography variant="h4" className={styles.heading}>Introduction</Typography>
			<Typography variant="body2">
				This document describes the steps that a POS solution provider needs to do to integrate with CASA. There is a total of seven integration points to ensure a robust integration with CASA. This ensure that the POS application can leverage all of CASA capabilities.
			</Typography>
			<Box className={styles.img}>
				<POSFlowImage />
			</Box>
		</Box>

		<Box id="ItemSync" className={styles.section}>
			<Typography variant="h4" className={styles.heading}>Item Sync</Typography>
			<Typography variant="body2">
				This integration <Link target="_blank" href='https://casa-api-docs.casaqa.ajira.tech/#req_526427d037b84769bacec44739ca0af6'>API</Link> ensures that POS can send complete item information to CASA.
			</Typography>
		</Box>

		{/* <Box id="StoreSync" className={styles.section}>
			<Typography variant="h4" className={styles.heading}>Store Sync</Typography>
			<Typography variant="body2">
				<Link target="_blank" href='#'>Store Sync API</Link> allows a POS vendor to send their entire store list along with their metadata to CASA. The format for calling CASA’s store sync API is provided in the following link. <br></br>
			</Typography>
		</Box> */}

		<Box id="BillSync" className={styles.section}>
			<Typography variant="h4" className={styles.heading}>Bill Sync</Typography>
			<Typography variant="body2">
				Bills can be sent to CASA through the <Link target="_blank" href='https://casa-api-docs.casaqa.ajira.tech/#req_59792f647649437c840cfa75a03d84fa'>Bill Sync API</Link>. There are four types of bills that can be sent to CASA. The billType field distinguishes the type of bill. The classifications are; <br/>
				<ol>
					<li><Typography variant='h6'>Sales Invoice Bill</Typography> SI denotes that it is a sales invoice bills</li>
					<li><Typography variant='h6'>Sales Return Bill</Typography> One or more items in the bill are returned. SR denotes that it is a return</li>
					<li><Typography variant='h6'>Exchange Bill</Typography> There is a return of item and additional purchase that happens. In CASA exchanges are treated as a return and purchase of new item</li>
					<li><Typography variant='h6'>Void Bill</Typography> API to indicate that a bill is cancelled</li>
				</ol>	
			</Typography>
		</Box>

		<Box id="BillReconciliation" className={styles.section}>
			<Typography variant="h4" className={styles.heading}>Bill Reconciliation</Typography>
			<Typography variant='body2'>
				To ensure all bills are consistently present in the system for a specific date. <br />

				Our system validates bills generated on the day "T-2" (two days prior to the current day). <br />

				For a streamlined bill reconciliation:<br />
				<br />
				<Typography variant='h6'>API Integration:</Typography> You can allow our system to pull billing data directly. The provided API access should:<br />
				<br />
				&nbsp;- Be for the specified date and customer.<br />
				&nbsp;- Have a response structure akin to the bill sync API.<br />
				<br />

				<Typography variant='h6'>CSV File Transfer via SFTP:</Typography> As an alternative, You can give billing data in CSV format. This can be done by placing files in a shared SFTP folder. Each CSV should:<br />
				<br />
				&nbsp;- Contain item-level records.<br />
				&nbsp;- Ensure each item record includes all mandatory fields as outlined in the bill sync API documentation.<br />
			</Typography>
		</Box>

		<Box id="Loyalty" className={styles.section}>
			<Typography variant="h4" className={styles.heading}>Loyalty</Typography>
			<Typography variant="body2">
				There are two integration modes for loyalty;<br/>
				<ol>
					<li>
						<Typography variant='h6'>Web based integration</Typography> 
						Calling the <Link target="_blank" href="https://casa-api-docs.casaqa.ajira.tech/#req_21d3a71083a249c4861c236dbc3f8c7c">URL</Link> will open up a web browser window for redemption of loyalty.
User will enter the points for redemption and submit which will close the window after
redemption
					</li>
					<li>
						<Typography variant='h6'>API based integration</Typography>
						<ol>
							<li>
								<Link target="_blank" href="https://casa-api-docs.casaqa.ajira.tech/#req_77d8f54446aa4f8aa90eabe2b5959aec">GET Loyalty Points API</Link>
							</li>
							<li><Link target="_blank" href="https://casa-api-docs.casaqa.ajira.tech/#req_1087036607b14dcf94e16169400cb61b">Loyalty points redeem api</Link></li>
							<li><Link target="_blank" href="https://casa-api-docs.casaqa.ajira.tech/#req_f213b61909124292b4f570aac1cb9535">Loyalty points unblock api</Link></li>
						</ol>
					</li>
				</ol>	
			</Typography>
		</Box>

		<Box id="Offer" className={styles.section}>
			<Typography variant="h4" className={styles.heading}>Offer</Typography>
			<Typography variant="body2">
				There are two integration modes for loyalty;<br/>
				<ol>
					<li>
						<Typography variant='h6'>Web based integration</Typography> 
						Calling the <Link target="_blank" href="https://casa-api-docs.casaqa.ajira.tech/#req_2ff8240b4a954e52a6f635bbddbd7762">URL</Link> will open up the browser<br/>
					</li>
					<li>
						<Typography variant='h6'>API based Integration</Typography>
						<ol>
							<li>
								To validate offer, use <Link target="_blank" href="https://casa-api-docs.casaqa.ajira.tech/#req_dd1d45bd64964c6ca63907d6ea26efee">Validate Offer API</Link>
							</li>
							<li>
								To list offers for a customer, use <Link target="_blank" href="https://casa-api-docs.casaqa.ajira.tech/#req_19f599e807d64660aff2a9bf8ec6eb59">List Offers for customer API</Link>
							</li>
						</ol>
					</li>
				</ol>	
			</Typography>
		</Box>

		<Box id="Customer360" className={styles.section}>
			<Typography variant="h4" className={styles.heading}>Customer 360</Typography>
			<Typography variant="body2">
				Customer details can be shown using the <Link target="_blank" href='https://casa-api-docs.casaqa.ajira.tech/#req_7bc91a486bc24bf9912e18c69dc20178'>Customer 360 Web Integration API</Link>. There are four types of bills that can be sent to CASA.
			</Typography>
		</Box>
	</Box>
}

export default POSIntegration;