import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  button: {
    fontWeight: 600,
  },
  fontFamily: 'Inter, sans-serif',
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.75,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.75,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.57,
  },
  overline: {
    fontSize: '0.7rem',
    fontWeight: 400,
    letterSpacing: '0.05em',
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '.7rem',
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: '.05em',
  },
  h1: {
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: 1.375,
  },
  h2: {
    fontWeight: 700,
    fontSize: '3rem',
    lineHeight: 1.375,
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: 1.375,
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: 1.375,
  },
  h5: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.375,
  },
  h6: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.375,
  },
};

export default typography;
