import { ListItemButton, ListItemText } from "@mui/material";
import React, { FC } from "react";
import NavigationLink from "../../../../interfaces/NavigationLink";

import styles from "./SidebarListItem.module.scss";
import useNavigationLink from "../../../../hooks/useNavigationLink";

interface SidebarListItemProps extends NavigationLink {
  isParentElement?: boolean;
  index: number
}

const SidebarListItem: FC<SidebarListItemProps> = ({
  label,
  index = 0
}) => {
  const { activeInSideBar, setActiveInSideBar } = useNavigationLink();
  const active = activeInSideBar === index;

  return (
    <>
      <button className={active ? styles.sidebarListItemActive : styles.sidebarListItem} onClick={() => setActiveInSideBar(index)}>
        <span>{label}</span>
      </button>
    </>
  );
};

export default SidebarListItem;
