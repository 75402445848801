import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';

import App from './App';
import theme from './theme';

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
    </>
    <CssBaseline />
    <Toaster position='top-right' containerStyle={{ top: 80 }} />
  </ThemeProvider>
);
