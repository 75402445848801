import React, { createContext, FC, PropsWithChildren, useState } from 'react';

import navConfig from '../constants/navigationLinks';
import NavigationLink from '../interfaces/NavigationLink';

interface NavListContextType {
  navigationLinks: NavigationLink[];
  activeInSideBar: number;
  setActiveInSideBar: (_: number) => void;
}

const initialValue = {
  navigationLinks: navConfig,
  activeInSideBar: 0,
  setActiveInSideBar: (_: number) => {}
};

const NavigationLinkContext = createContext<NavListContextType>(initialValue);

export const NavigationLinkProvider: FC<PropsWithChildren> = ({ children }) => {

  const [activeInSideBar, setActiveInSideBar] = useState(0);
  
  const initialValue = {
    navigationLinks: navConfig,
    activeInSideBar,
    setActiveInSideBar
  };


  return (
    <NavigationLinkContext.Provider value={initialValue}>
      {children}
    </NavigationLinkContext.Provider>
  );
};

export default NavigationLinkContext;
