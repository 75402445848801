import POSIntegration from './POSIntegration/POSIntegration';
import { Box, Container } from '@mui/material';
import React, {  useState } from 'react';
import { ScrollRestoration } from 'react-router-dom';

import styles from './Dashboard.module.scss';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
// import ListOfContents from './ListOfContents/ListOfContents';

const Dashboard = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const handleToggleSidebar = (): void => {
    setIsSidebarActive((isSidebarActive) => !isSidebarActive);
  };

  return (
    <Box id={styles.dashboard}>
      <Navbar onToggleSidebar={handleToggleSidebar} />
      <Box className={styles.app}>
        <Sidebar
          isSidebarActive={isSidebarActive}
          onCloseSidebar={setIsSidebarActive.bind(null, false)}
        />
        <Container component='main' className={styles.container}>
          <Box className={styles.posContainer}>
            <POSIntegration />
          </Box>
          {/* <Box className={styles.listOfContentsContainer}>
            <ListOfContents />
          </Box> */}
        </Container>
      </Box>
      <ScrollRestoration />
    </Box>
  );
};

export default Dashboard;
