import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import _ from 'lodash';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { classnames } from '../../../../utils';
import { FuzzySearchNavigationLink } from '../../utils/FuzzySearch';
import InstantSearchList from '../InstantSearchList/InstantSearchList';
import styles from './InstantSearchListItem.module.scss';

interface InstantSearchListItemProps extends FuzzySearchNavigationLink {
  isParentElement?: boolean;
  onClose: () => void;
}

const InstantSearchListItem: FC<InstantSearchListItemProps> = ({
  content,
  label,
  matches,
  isParentElement = false,
  onClose,
}) => {
  const listLabel = matches?.length
    ? matches.map(({ text, highlight }, i) => (
        <span
          key={text + i}
          className={classnames({ [styles.highLightText]: highlight })}
        >
          {text}
        </span>
      ))
    : label;

  if (_.isString(content))
    return (
      <ListItemButton
        onClick={onClose}
        id={styles.instantSearchListItem}
        className={classnames({ [styles.listItemChild]: !isParentElement })}
        disableRipple
        component={Link}
        to={content}
        sx={{ '&:hover .MuiTypography-root': { color: 'primary.main' } }}
      >
        <ListItemText
          primary={listLabel}
          sx={{
            color: 'secondary.dark',
            w: '100%',
          }}
        />
      </ListItemButton>
    );

  return (
    <>
      <Box component='li' id={styles.listCaption}>
        <Typography
          variant='overline'
          color='gray'
          className={styles.listCaptionText}
        >
          {listLabel}
        </Typography>
      </Box>
      {_.isArray(content) && (
        <InstantSearchList list={content} onClose={onClose} />
      )}
    </>
  );
};

export default InstantSearchListItem;
