import NavigationLink from '../interfaces/NavigationLink';

const navConfig: NavigationLink[] = [
  {
    label: 'POS Integration Docket',
    key: 'POSIntegration',
    content: [
      {
        label: 'Introduction',
        key: 'Introduction',
        content: []
      },
      {
        label: 'Item Sync',
        key: 'ItemSync',
        content: []
      },
      {
        label: 'Store Sync',
        key: 'StoreSync',
        content: []
      },
      {
        label: 'Bill Sync',
        key: 'BillSync',
        content: []
      },
      {
        label: 'Bill Reconciliation',
        key: 'BillReconciliation',
        content: []
      }
    ]
  },
  // {
  //   label: 'Loyalty Integrations',
  //   key: 'LoyaltyIntegration',
  //   content: []
  // },
  // {
  //   label: 'ECom Integrations',
  //   key: 'EComIntegration',
  //   content: []
  // },
];

export default navConfig;
