import React from 'react';
import { NavigationLinkProvider } from './contexts/NavigationLink';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './routes';

const router = createBrowserRouter(routes);

function App() {
  return (
    <NavigationLinkProvider>
        <RouterProvider router={router} />
      </NavigationLinkProvider>
  );
}

export default App;
